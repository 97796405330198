<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
				<div class="tableConTable" v-loading="loading">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_1ba6fbd520bc16df')}}</span>
									<!-- <el-tooltip effect="dark" content="undefined" placement="top"> -->
									<!-- <el-button type="primary" circle icon="el-icon-setting" size="mini" @click="selShowHeader()" ></el-button> -->
									<!-- </el-tooltip> -->
									<!-- <el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
                            <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
                          </el-tooltip> -->
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('i18nn_4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">undefined</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openAddNew()">
									{{ $t('i18nn_e8564657bbe9ca53') }}
								</el-button> -->
								<!-- </span> -->
								<!-- <el-button @click="completeAction($event, null)" type="success" size="small"
									icon="el-icon-finished">{{ 'undefined' }}</el-button> -->

								<!-- <el-button @click="addToAction()" type="warning" size="small" icon="el-icon-plus">{{ 'undefined' }}</el-button> -->

								<!-- <el-button @click="addToBillAction()" type="warning" size="small" icon="el-icon-plus">{{ 'undefined' }}</el-button> -->
								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<!-- <div class="" style="padding: 10px;">
							<div class="">
								<span>{{ $t('i18nn_29991afa9781d554') }}</span>
								<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
							</div>
						</div> -->
						<div class="filterCon" style="">
							<!-- <div class="filterBarList">
								<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
								  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">{{item.label}}</el-menu-item>
								</el-menu>
							</div> -->
							<!-- <el-row type="flex" justify="end"> -->
							<!-- <el-col :span="24"> -->
							<ul class="filterConList">
								<!-- <li>
									<span>
										{{ $t('i18nn_29991afa9781d554') }}
									</span>
									<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
								</li> -->
								<!-- <li>
									<span>
										{{ $t('i18nn_c944a6686d996ab3') }}
									</span>
									
									<whNoSelect @changeData="changWhNo"></whNoSelect>
								</li> -->
								<!-- <li>
									<span>{{$t('i18nn_4dc6f04018e0f73b')}}</span>
									<el-select filterable clearable v-model="filterData.qaType"
										:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;"
										@change="initData()">
										<el-option v-for="item in selectOption.wh_qa_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li> -->
								
								<!-- <li>
									<span>{{$t('i18nn_9666c59aedbfa70e')}}</span>
									<el-select filterable clearable v-model="filterData.wh_qa_biz_type"
										:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;"
										@change="initData()">
										<el-option v-for="item in selectOption.wh_qa_biz_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li> -->
								
								<li>
									<span>{{$t('i18nn_61fc9e07fff97930')}}</span>
									<el-select filterable clearable v-model="filterData.wh_op_add_service"
										:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;"
										@change="initData()">
										<el-option v-for="item in selectOption.wh_op_add_service" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li>
								
								<li>
									<span>{{ $t('i18nn_6235565b185f0725') }}</span>
									<el-input type="text" v-model="filterData.workNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 200px;" />
								</li>
								<li>
									<span>{{$t('i18nn_52a8c38184d84581')}}</span>
									<el-input type="text" v-model="filterData.sysOrderNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 200px;" />
								</li>
								
								<li>
									<span>{{$t('i18nn_9168144190f66f5d')}}</span>
									<el-input type="text" v-model="filterData.relationNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 200px;" />
								</li>
								
								
								
								<!-- <li>
									<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
									<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
										maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
									</el-input>
								</li> -->
								
								<!-- <li>
									<span>{{ $t('i18nn_6235565b185f0725') }}</span>
									<el-input type="text" v-model="filterData.workNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 220px;" />
								</li>

								<li>
									<span>{{ 'undefined' }}</span>
									<el-input type="text" v-model="filterData.opNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 180px;" />
								</li> -->

								<!-- <li>
									<span>{{$t('i18nn_d99d790ec4383bfb')}}</span>
									<el-date-picker v-model="filterData.daterange" size="small" type="daterange"
										align="right" :clearable="true" unlink-panels range-separator="-"
										start-placeholder="start date" end-placeholder="end date"
										:picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										@change="initData()"></el-date-picker>
								</li> -->
								<li>
									<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
										{{ $t('i18nn_1e7246dd6ccc5539') }}
									</el-button>
								</li>
							</ul>
							<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>       </el-select> -->
							<!-- </el-col> -->
							<!-- <el-col :span="5">
                  <span>undefined</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" placeholder="undefined" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
							<!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">undefined</el-button></el-col> -->
							<!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>

					<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
						:height="$store.state.frameConHeightWh" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" style="width: 100%" size="small">

						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						
						<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')">
							<template slot-scope="scope">
							  <div>
							    <!-- <el-link type="primary" @click="toWorkOrder($event,scope.row)">{{scope.row.workNo}}</el-link> -->
									
									<div class="link-copy">
										<span class="link-text" @click="toWorkOrder($event, scope.row, scope.$index)">
											{{ scope.row.workNo }}
										</span>
										<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
										  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.workNo)"></i>
										</el-tooltip>
									</div>
									
							  </div>
							</template>
						</el-table-column>

						<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')"></el-table-column>
						<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')"></el-table-column>
						<el-table-column prop="bizTypeName" :label="$t('i18nn_32b164c53fa35e6d')">
							<template slot-scope="scope">
								<div>
									{{ $Utils.i18nKeyText(scope.row,'bizTypeName') }}
								</div>
							</template>
						</el-table-column>
						
						<el-table-column prop="serviceTypeName" :label="$t('i18nn_61fc9e07fff97930')">
							<template slot-scope="scope">
								<div>
									{{ $Utils.i18nKeyText(scope.row,'serviceTypeName') }}
								</div>
							</template>
						</el-table-column>
						
						<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')+'(和'+$t('i18nn_61fc9e07fff97930')+'相关)'"></el-table-column>
						<el-table-column prop="goodsSku" :label="'SKU'+'(非必填)'"></el-table-column>
						<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column> -->

						<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
							<template slot-scope="scope">
								<div>
									<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
									<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
								</div>
							</template>
						</el-table-column>

						<!-- <el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="300px" fixed="right">
							<template slot-scope="scope">
								<div>
									<el-button @click="openRecProgress($event, scope.row)" type="success" size="mini"
										icon="el-icon-view">{{$t('i18nn_10a6a2a6124bbb9a')}}</el-button>
									<el-button type="danger" size="mini" icon="el-icon-minus"
										@click="delAction($event, scope.row)" v-if="'10' == scope.row.status">
										{{ $t('i18nn_e33c9b93c36fd250') }}
									</el-button>
								</div>
							</template>
						</el-table-column> -->
						
					</el-table>
				</div>
				<div class="tableConPagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			// cusSelFuzzy,
			// whNoSelect,
			// BillingRecords
		},
		data() {
			return {
				cusUserId: '',
				//打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				// selBillData: {},
				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				multipleSelection: [],

				// pickerOptions: {
				// 	shortcuts: [{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Three M',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 	]
				// },

				selectOption: {
					wh_op_add_service:[],
					wh_no: [],
					wh_qa_type: [],
					wh_qa_biz_type:[],
					// wh_op_status: [],
					// wh_fin_bill_status: [],
					// wh_op_record_type: [],
					// // wh_op_is_bill:[],
					// statusList: [{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	// {
					// 	// 	value: '10',
					// 	// 	label: this.$t('i18nn_7e14d2cd3996dcd2')
					// 	// },
					// 	// {
					// 	// 	value: '20',
					// 	// 	label: this.$t('i18nn_2b65059530ec3ed5')
					// 	// },
					// 	// {
					// 	// 	value: '30',
					// 	// 	label: this.$t('i18nn_1fb4422ebabb847a')
					// 	// },
					// 	// {
					// 	// 	value: '40',
					// 	// 	label: this.$t('i18nn_0eb9b2e0d01ad12b')
					// 	// }
					// ]
				},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//详情数据
				loading_det: false,

				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					workNo: '', //this.$t('i18nn_daf169d1f7dceda0'),
					// qaNo: "",
					
					whNo: '', //this.$t('i18nn_5e7740c52e22eefc'),
					qaType: "",
					status:"",
					// wh_op_is_bill:'',
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
					// 	.$Utils.fomatterDate_YYYYMMdd(new Date())
					// ],
					daterange: [],
					relationNo:"",
					sysOrderNo:"",
					wh_qa_biz_type:"",
					wh_op_add_service:"",
					keyword:"",
					// wh_op_status: '',
					// wh_fin_bill_status: '',
					// plStatus:'',
					// bizType: '10',
					// opNo: ''
					// plName: ''
				}
			};
		},
		activated() {
			console.log('activated');
			// if (this.$route.query && this.$route.query.cusUserId) {
			// 	this.cusUserId = this.$route.query.cusUserId;
			// }
			// this.$nextTick(() => {
			// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
			// })
			// this.initData();
		},
		//创建时
		created() {
			// if (this.$route.query && this.$route.query.cusUserId) {
			// 	this.cusUserId = this.$route.query.cusUserId;
			// }
			// this.$nextTick(() => {
			// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
			// })
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_qa_type','wh_qa_biz_type','wh_op_add_service'],
				(data)=>{
					this.selectOption.wh_qa_type = data.['wh_qa_type'];
					this.selectOption.wh_qa_biz_type = data['wh_qa_biz_type'];
					this.selectOption.wh_op_add_service = data['wh_op_add_service'];
			});
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];

				this.getPageData();
				
				this.$nextTick(()=>{
					this.$refs.multipleTable.doLayout();
				})
			},
			changCus(data) {
				console.log('changCus', data);
				this.cusUserId = data.userId;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			
			handleSelectStatus(key, keyPath) {
			  // console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			//查看工单
			toWorkOrder(event, row){
				event.stopPropagation();
				// this.dialogAddVisible = false;
				this.$emit('close');
				this.$router.push({
					name:"workOrderList",
					query:{
						workNo: row.workNo
					}
				})
			},
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	this.form.cusName = data.companyName;

			// 	this.form.cusNo = data.cusNo;
			// },

			//分页的筛选项数据
			pageFilterData() {
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	// this.$message.warning(this.$t('tips.Please_Sel_date'));
				// }
				return {
					userId: this.cusUserId ? this.cusUserId : null,
					// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					qaType: this.filterData.qaType ? this.filterData.qaType : null,
					// qaNo: this.filterData.qaNo ? this.filterData.qaNo : null,
					workNo: this.filterData.workNo ? this.filterData.workNo : null,
					// wh_op_is_bill: this.filterData.wh_op_is_bill? this.filterData.wh_op_is_bill:null,
					// isBill: this.filterData.wh_fin_bill_status ? this.filterData.wh_fin_bill_status : null,
					status: this.filterData.status ? this.filterData.status : null,
					
					qaBizType: this.filterData.wh_qa_biz_type ? this.filterData.wh_qa_biz_type : null,
					relationNo: this.filterData.relationNo ? this.filterData.relationNo : null,
					
					sysOrderNo: this.filterData.sysOrderNo ? this.filterData.sysOrderNo : null,
					
					serviceType: this.filterData.wh_op_add_service ? this.filterData.wh_op_add_service : null,
					// opNo: this.filterData.opNo ? this.filterData.opNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// startOpTime: startTime ? startTime : null,
					// endOpTime: endTime ? endTime : null,

					// bizType: this.filterData.bizType ? this.filterData.bizType : null,
					// "plName": this.filterData.plName? this.filterData.plName:null,
					// "goodsType": this.queryParamObj.goodsType
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhValAddServicePageList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;

							//当前数据总条数
							this.pagination.total = parseInt(data.total);

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},

			//打开新增
			openAddNew() {
				event.stopPropagation();
				this.$router.push({
					name: 'QARecAdd'
				});
			},
			
			//打开编辑
			openRecProgress(event, row) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
				this.$router.push({
					name: 'QARecProgress',
					query: {
						id: row.id,
						qaNo: row.qaNo
					}
				});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//删除
			delAction(event, row) {
				this.$confirm('确定删除吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delAction', row);
				let parm = [];
				parm = {};
				this.postData(this.$urlConfig.WhQARecDel + '/' + row.id, parm, 'delete', () => {
					this.initData();
					this.$message.success(this.$t('9f30371831a98237'));
				});
			},

			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_qa_type','wh_qa_biz_type','wh_op_add_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_qa_type = data.data['wh_qa_type'];
			// 				this.selectOption.wh_qa_biz_type = data.data['wh_qa_biz_type'];
			// 				this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
